import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import { EditAttributes } from "design-system/types/types";
import { ReactNode } from "react";

export interface HierarchicalTeaseHeaderProps {
  componentHeader?: ComponentHeaderProps;

  overline?: string;
  title?: string;
  subheading?: ReactNode;
  cta?: CtaLinkProps;

  editAttributes?: {
    title?: EditAttributes;
    overline?: EditAttributes;
    subheading?: EditAttributes;
  };
}

export function HierarchicalTeaseHeader({
  componentHeader,
  overline,
  title,
  subheading,
  cta,
  editAttributes,
}: HierarchicalTeaseHeaderProps) {
  if (componentHeader) {
    return (
      <ComponentHeader
        {...componentHeader}
        className="hbs-hierarchical-tease__component-header"
      />
    );
  }

  return (
    <div className="hbs-hierarchical-tease__content">
      {overline && (
        <p
          {...editAttributes?.overline}
          className="hbs-hierarchical-tease__overline"
        >
          {overline}
        </p>
      )}
      {title && (
        <h2
          {...editAttributes?.title}
          className="hbs-hierarchical-tease__title"
        >
          {title}
        </h2>
      )}
      {subheading && (
        <div
          {...editAttributes?.subheading}
          className="hbs-hierarchical-tease__subheading"
        >
          {subheading}
        </div>
      )}
      {cta && (
        <div className="hbs-hierarchical-tease__cta">
          <CtaLink {...cta} isSmall>
            {cta.children}
          </CtaLink>
        </div>
      )}
    </div>
  );
}
